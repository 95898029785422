<template>
	<div class="flex-row flex-gap-large">

		<div class="flex-item">
			<div class="input-group required">
				<label for="date_study_end">Date de sortie d’étude</label>
				<input id="date_study_end"
							 name="date_study_end"
							 type="date"
							 v-bind="$attrs"
							 v-model="localModel.date_study_end"
							 @input="emitScore"
							 required />
			</div>
		</div>

		<div class="flex-item--grow">
			<div class="input-group required">
				<label for="last_session_week">Dernière semaine de traitement</label>
				<div class="input-group-prefix"
						 data-prefix="S">
					<input id="last_session_week"
								 name="last_session_week"
								 type="number"
								 min="1"
								 step="1"
								 v-bind="$attrs"
								 v-model="localModel.last_session_week"
								 @input="emitScore"
								 required
								 style="width:5rem" />
				</div>
			</div>
		</div>

		<div class="flex-item--grow flex-col flex-gap">
			<p class="label">En cas de fin prématuré du traitement, merci d’en préciser la cause :</p>

			<div class="input-group">
				<label v-for="(caption, index) in premature_treatment_end_options"
							 :key="`premature_treatment_end_${index}`">
					<input type="checkbox"
								 :id="`premature_treatment_end_${index}`"
								 :name="`premature_treatment_end_${index}`"
								 :value="caption"
								 v-bind="$attrs"
								 v-model="localModel.premature_treatment_end"
								 @input="emitScore" />
					<span class="caption">{{ caption }}</span>
				</label>
			</div>

			<div class="flex-item--grow"
					 v-if="otherVisible">
				<div class="input-group required">
					<label for="premature_treatment_other">Précisez :</label>
					<textarea cols="60"
										rows="3"
										name="premature_treatment_other"
										id="premature_treatment_other"
										v-model="localModel.premature_treatment_other"
										v-bind="$attrs"
										required />
				</div>
			</div>
		</div>


		<div class="flex-item--grow flex-row flex-wrap flex-center-v flex-gap">

			<div class="flex-item--grow">
				<div class="input-group--inline flex-nowrap required"
						 style="flex-wrap: nowrap !important;">
					<input type="checkbox"
								 name="user_signature"
								 id="user_signature"
								 value="1"
								 v-bind="$attrs"
								 v-model="localModel.user_signature"
								 @change="emitScore"
								 required
								 style="flex-basis: 1rem; flex-grow: 0 !important; min-width: auto;" />
					<label for="user_signature"
								 class="caption">
						Je soussigné(e), Pr/Dr <strong>{{ user?.firstname }} {{ user?.lastname }}</strong>, médecin investigateur, certifie l’exactitude des données recueillies dans l’e-CRF.
					</label>
				</div>
			</div>

			<div class="flex-item">
				<div class="input-group required">
					<label for="user_signature_date">Signé le </label>
					<input id="user_signature_date"
								 name="user_signature_date"
								 type="date"
								 v-bind="$attrs"
								 v-model="localModel.user_signature_date"
								 @input="emitScore"
								 required />
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'StudyEnd',
	inheritAttrs: false,
	props: ['value'],

	data() {
		return {
			localModel: {
				date_study_end: "",
				last_session_week: "",
				premature_treatment_end: [],
				premature_treatment_other: "",
				user_signature: "",
				user_signature_date: ""
			},

			premature_treatment_end_options: [
				"Présence d’évènement indésirable grave (EIG)",
				"Souhait d’arrêter du traitement",
				"Perdu de vue",
				"Autre",
			],
		}
	},

	computed: {
		otherVisible() {
			return this.localModel?.premature_treatment_end?.includes("Autre");
		}
	},

	methods: {
		emitScore(value) {
			this.$emit('input', this.localModel);
		}
	},

	watch: {
		// Init data values from component props ("values")
		value: {
			immediate: true, deep: true,
			handler: function (data) {
				if (data) this.localModel = { ...data };
				// init array…
				if (!this.localModel?.premature_treatment_end) this.localModel.premature_treatment_end = [];
				if (!this.localModel?.user_signature_date) this.localModel.user_signature_date = new Date().toISOString().slice(0, 10);
			}
		},
	}

}

</script>